import React from 'react';
import { graphql } from 'gatsby';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { HomeLayout } from '../components/homeLayout';
import SEO from '../components/seo';

// eslint-disable-next-line import/extensions,import/no-unresolved
import { PayReparationsQuery } from '../../types/graphql-types';
import PayReparationsComponent from '../components/join/payReparations';
import BannerPage from '../components/join/bannerPage';

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY || '');

type Props = {
  location: {
    hash: string;
  };
  data: PayReparationsQuery;
};

const PayReparations: React.FC<Props> = ({ data, location }: Props) => (
  <HomeLayout pathname="/join">
    <SEO
      title="White Reparations to African People"
      description="Uhuru Solidarity Movement is the reparations organization of white people under the leadership of the African People's Socialist Party"
    />

    <BannerPage joinBanner={data.joinBanner}>
      <Elements stripe={stripePromise}>
        <PayReparationsComponent hash={location.hash} data={data} />
      </Elements>
    </BannerPage>
  </HomeLayout>
);

// TODO: use a different banner for pay-reparations?
// TODO: add thumbnail for the "Where does the money go?" video
export const query = graphql`
  query PayReparations {
    joinBanner: file(relativePath: { eq: "pay-banner-3.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 1720)
      }
    }
    payReparationsVideo: contentfulVideo(
      title: { eq: "The Black Power Blueprint" }
    ) {
      embedUrl
      thumbnail {
        gatsbyImageData(layout: CONSTRAINED, width: 480, cropFocus: CENTER)
      }
    }
  }
`;

export default PayReparations;
