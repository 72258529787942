import React, { useEffect, useState } from 'react';

import { navigate } from 'gatsby';
import { PayReparationsSteps } from './types';
import { Header1 } from '../headers';
import { numberWithCommas } from '../../utils';
import PaymentOptionToggle from './paymentOptionToggle';
import { usmPayReparationsLevels } from './constants';
import PaymentForm from './paymentForm';
import usePaymentForm from './usePaymentForm';
import PaymentButton from './paymentButton';
import Video from '../video';
import CustomAmount from './customAmount';

// eslint-disable-next-line import/extensions,import/no-unresolved
import { PayReparationsQuery } from '../../../types/graphql-types';
import QuestionnaireForm from './questionnaireForm';
import useQuestionnaireForm from './useQuestionnaireForm';

type Props = {
  hash: string;
  data: PayReparationsQuery;
};

const PayReparations: React.FC<Props> = ({ hash, data }: Props) => {
  // TODO: abstract hash navigation
  const hashStripped = hash.substr(1);
  const [step, setStep] = useState<PayReparationsSteps>(
    Object.values(PayReparationsSteps).includes(
      hashStripped as PayReparationsSteps
    )
      ? (hashStripped as PayReparationsSteps)
      : PayReparationsSteps.PaymentLevel
  );

  useEffect(() => {
    navigate(`/pay-reparations#${PayReparationsSteps.PaymentLevel}`, {
      replace: true,
    });
  }, []);

  useEffect(() => {
    if (hashStripped === '' && step === PayReparationsSteps.PaymentLevel) {
      navigate(`/pay-reparations#${PayReparationsSteps.PaymentLevel}`, {
        replace: true,
      });
    }
    if (hashStripped !== step) {
      if (
        Object.values(PayReparationsSteps).includes(
          hashStripped as PayReparationsSteps
        )
      ) {
        setStep(hashStripped as PayReparationsSteps);
      }
    }
  }, [hashStripped, step]);

  const onSetStep = (newStep: PayReparationsSteps) => {
    navigate(`/pay-reparations#${newStep}`);
    setStep(newStep);
  };

  const {
    onTieredAmountSelected,
    onCustomAmountSelected,
    tieredAmountSelected,
    customAmountSelected,
    amountDollars,
    paymentFormData,
    error,
    loading,
    onInputChange,
    onSubmit,
    rowId,
  } = usePaymentForm(
    undefined,
    { stripeProductId: '', name: '', type: 'monthly', amount: 25 },
    () => onSetStep(PayReparationsSteps.DonationQuestionnaire)
  );

  const {
    error: questionnaireError,
    loading: questionnaireLoading,
    onInputChange: questionnaireInputChange,
    onSubmit: questionnaireSubmit,
    questionnaireFormData,
  } = useQuestionnaireForm(paymentFormData, rowId || 0, () =>
    onSetStep(PayReparationsSteps.ThankYouPage)
  );

  const onSetCustomAmount = (amount: number | undefined): void =>
    onCustomAmountSelected(
      typeof amount !== 'undefined'
        ? {
            amount,
            type: 'monthly',
          }
        : undefined
    );

  return (
    <div className="pb-10">
      {(step === PayReparationsSteps.PaymentLevel ||
        step === PayReparationsSteps.PaymentInfo) && (
        <>
          <Header1 tag="h1" className="text-usm-dark-red uppercase text-center">
            Yes, I Want to Pay Reparations!
          </Header1>
          <div className="mt-3 w-full md:w-96 mx-auto">
            {data.payReparationsVideo?.thumbnail &&
              data.payReparationsVideo.embedUrl && (
                <Video
                  videoUrl={data.payReparationsVideo.embedUrl}
                  photo={data.payReparationsVideo.thumbnail}
                />
              )}
          </div>
          <p className="text-center mt-4 md:px-16">
            Pay reparations towards the Black Power Blueprint, building
            political and economic power in the hands of the African working
            class as a part of the foundation of the anti-colonial, liberated,
            African workers’ economy.
          </p>
        </>
      )}
      {(step === PayReparationsSteps.DonationQuestionnaire ||
        step === PayReparationsSteps.ThankYouPage) && (
        <>
          <Header1
            tag="h1"
            className="text-usm-dark-red uppercase text-center md:px-10"
          >
            Thank you for your stand of Reparations to African People
          </Header1>

          <p className="text-center mt-4 md:px-8">
            Your contribution of reparations to the African self-determination
            programs of the Uhuru Movement is making a profound difference. It
            is a stand of solidarity, not charity. We salute you for your
            contribution to the anti-colonial movement of African and oppressed
            peoples fighting to build a future free from oppression and
            exploitation. This is what reparations looks like.
          </p>
        </>
      )}
      {step === PayReparationsSteps.PaymentLevel && (
        <>
          <div
            className="flex flex-wrap mt-8 mx-auto"
            style={{ maxWidth: 550 }}
          >
            {error && (
              <div className="text-center min-w-full text-xs text-usm-dark-red mb-2">
                {error}
              </div>
            )}
            {usmPayReparationsLevels.map((level) => (
              <PaymentOptionToggle
                isSelected={level.amount === tieredAmountSelected?.amount}
                onToggle={() => onTieredAmountSelected(level)}
                key={level.amount}
              >
                ${numberWithCommas(level.amount)}
              </PaymentOptionToggle>
            ))}
          </div>
          <CustomAmount
            isExpanded={typeof customAmountSelected !== 'undefined'}
            defaultCustomAmount={3000}
            onCustomAmountChange={onSetCustomAmount}
          />

          <PaymentButton
            onSubmit={() => {
              if (!error) {
                onSetStep(PayReparationsSteps.PaymentInfo);
              }
            }}
            amountSelected={amountDollars}
          />
        </>
      )}
      {step === PayReparationsSteps.PaymentInfo && (
        <PaymentForm
          onSubmit={onSubmit}
          onInputChange={onInputChange}
          data={paymentFormData}
          error={error}
          amountSelected={amountDollars}
          loading={loading}
          includeComments
        />
      )}
      {step === PayReparationsSteps.DonationQuestionnaire && (
        <QuestionnaireForm
          data={questionnaireFormData}
          onSubmit={questionnaireSubmit}
          loading={questionnaireLoading}
          error={questionnaireError}
          onInputChange={questionnaireInputChange}
          questionnaireType="donation"
        />
      )}
    </div>
  );
};

export default PayReparations;
